export const languages = [
	{ id: 100, name: "HTML/CSS" },
	{ id: 45, name: "Assembly (NASM 2.14.02)" },
	{ id: 46, name: "Bash (5.0.0)" },
	{ id: 47, name: "Basic (FBC 1.07.1)" },
	{ id: 75, name: "C (Clang 7.0.1)" },
	{ id: 76, name: "C++ (Clang 7.0.1)" },
	{ id: 48, name: "C (GCC 7.4.0)" },
	{ id: 52, name: "C++ (GCC 7.4.0)" },
	{ id: 49, name: "C (GCC 8.3.0)" },
	{ id: 53, name: "C++ (GCC 8.3.0)" },
	{ id: 50, name: "C (GCC 9.2.0)" },
	{ id: 54, name: "C++ (GCC 9.2.0)" },
	{ id: 86, name: "Clojure (1.10.1)" },
	{ id: 51, name: "C# (Mono 6.6.0.161)" },
	{ id: 77, name: "COBOL (GnuCOBOL 2.2)" },
	{ id: 55, name: "Common Lisp (SBCL 2.0.0)" },
	{ id: 56, name: "D (DMD 2.089.1)" },
	{ id: 57, name: "Elixir (1.9.4)" },
	{ id: 58, name: "Erlang (OTP 22.2)" },
	{ id: 44, name: "Executable" },
	{ id: 87, name: "F# (.NET Core SDK 3.1.202)" },
	{ id: 59, name: "Fortran (GFortran 9.2.0)" },
	{ id: 60, name: "Go (1.13.5)" },
	{ id: 88, name: "Groovy (3.0.3)" },
	{ id: 61, name: "Haskell (GHC 8.8.1)" },
	{ id: 62, name: "Java (OpenJDK 13.0.1)" },
	{ id: 63, name: "JavaScript (Node.js 12.14.0)" },
	{ id: 78, name: "Kotlin (1.3.70)" },
	{ id: 64, name: "Lua (5.3.5)" },
	{ id: 89, name: "Multi-file program" },
	{ id: 79, name: "Objective-C (Clang 7.0.1)" },
	{ id: 65, name: "OCaml (4.09.0)" },
	{ id: 66, name: "Octave (5.1.0)" },
	{ id: 67, name: "Pascal (FPC 3.0.4)" },
	{ id: 85, name: "Perl (5.28.1)" },
	{ id: 68, name: "PHP (7.4.1)" },
	{ id: 43, name: "Plain Text" },
	{ id: 69, name: "Prolog (GNU Prolog 1.4.5)" },
	{ id: 70, name: "Python (2.7.17)" },
	{ id: 71, name: "Python (3.8.1)" },
	{ id: 80, name: "R (4.0.0)" },
	{ id: 72, name: "Ruby (2.7.0)" },
	{ id: 73, name: "Rust (1.40.0)" },
	{ id: 81, name: "Scala (2.13.2)" },
	{ id: 82, name: "SQL (SQLite 3.27.2)" },
	{ id: 83, name: "Swift (5.2.3)" },
	{ id: 74, name: "TypeScript (3.7.4)" },
	{ id: 84, name: "Visual Basic.Net (vbnc 0.0.0.5943)" },
];

export const languageMap = {
	43: "Plain Text",
	44: "Executable",
	45: "Assembly (NASM 2.14.02)",
	46: "Bash (5.0.0)",
	47: "Basic (FBC 1.07.1)",
	48: "C (GCC 7.4.0)",
	49: "C (GCC 8.3.0)",
	50: "C (GCC 9.2.0)",
	51: "C# (Mono 6.6.0.161)",
	52: "C++ (GCC 7.4.0)",
	53: "C++ (GCC 8.3.0)",
	54: "C++ (GCC 9.2.0)",
	55: "Common Lisp (SBCL 2.0.0)",
	56: "D (DMD 2.089.1)",
	57: "Elixir (1.9.4)",
	58: "Erlang (OTP 22.2)",
	59: "Fortran (GFortran 9.2.0)",
	60: "Go (1.13.5)",
	61: "Haskell (GHC 8.8.1)",
	62: "Java (OpenJDK 13.0.1)",
	63: "JavaScript (Node.js 12.14.0)",
	64: "Lua (5.3.5)",
	65: "OCaml (4.09.0)",
	66: "Octave (5.1.0)",
	67: "Pascal (FPC 3.0.4)",
	68: "PHP (7.4.1)",
	69: "Prolog (GNU Prolog 1.4.5)",
	70: "Python (2.7.17)",
	71: "Python (3.8.1)",
	72: "Ruby (2.7.0)",
	73: "Rust (1.40.0)",
	74: "TypeScript (3.7.4)",
	75: "C (Clang 7.0.1)",
	76: "C++ (Clang 7.0.1)",
	77: "COBOL (GnuCOBOL 2.2)",
	78: "Kotlin (1.3.70)",
	79: "Objective-C (Clang 7.0.1)",
	80: "R (4.0.0)",
	81: "Scala (2.13.2)",
	82: "SQL (SQLite 3.27.2)",
	83: "Swift (5.2.3)",
	84: "Visual Basic.Net (vbnc 0.0.0.5943)",
	85: "Perl (5.28.1)",
	86: "Clojure (1.10.1)",
	87: "F# (.NET Core SDK 3.1.202)",
	88: "Groovy (3.0.3)",
	89: "Multi-file program",
	100: "HTML/CSS",
};

export const monacoMap = {
	43: "Plain Text",
	52: "cpp",
	53: "cpp",
	54: "cpp",
	62: "java",
	63: "javascript",
	70: "python",
	71: "python",
	100: "html",
};
